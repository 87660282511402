import React from "react"
import theme from "theme"
import { Theme, Image, Box, Text, Link, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>O nas | Odkryj Exoniva Studio</title>
        <meta
          name={"description"}
          content={
            "Każdy samochód ma swoją historię, sprawmy, by Twój błyszczał! "
          }
        />
        <meta property={"og:title"} content={"O nas | Odkryj Exoniva Studio"} />
        <meta
          property={"og:description"}
          content={
            "Każdy samochód ma swoją historię, sprawmy, by Twój błyszczał! "
          }
        />
        <meta
          property={"og:image"}
          content={"https://exoniva.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://exoniva.com/img/437382387.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://exoniva.com/img/437382387.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://exoniva.com/img/437382387.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://exoniva.com/img/437382387.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://exoniva.com/img/437382387.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://exoniva.com/img/437382387.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          justify-content="flex-start"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 32px 0px 0px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://exoniva.com/img/4.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            border-radius="24px"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            min-height="600px"
            max-width="480px"
            sm-min-height="100vw"
          />
        </Box>
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 0px 16px 16px"
          justify-content="center"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--dark"
            font="--headline1"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            O nas
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD3"
            font="--lead"
            lg-text-align="center"
          >
            Witamy w Exoniva Studio, gdzie wierzymy, że każdy samochód zasługuje
            na blask! Nasza podróż rozpoczęła się od prostej wizji: stworzenia
            przyjaznego środowiska do pielęgnacji samochodów, w którym każdy
            pojazd będzie traktowany jak supergwiazda. Od skromnych początków z
            myjnią ręczną i kilkoma zestawami do woskowania, rozwinęliśmy się w
            studio oferujące kompleksowy zakres usług detailingowych. Nasza
            pasja do samochodów i zaangażowanie w jakość sprawiły, że staliśmy
            się ulubionym wyborem entuzjastów motoryzacji.
          </Text>
          <Link
            href="/contact"
            padding="12px 24px 12px 24px"
            color="--light"
            text-decoration-line="initial"
            font="--lead"
            border-radius="8px"
            margin="0px 16px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            sm-margin="0px 0px 16px 0px"
            sm-text-align="center"
            background="--color-primary"
          >
            Odwiedź nas
          </Link>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Nasze usługi
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            W Exoniva Studio zapewniamy coś więcej niż tylko mycie - zapewniamy
            pełną opiekę nad samochodem. Nasze usługi obejmują zaawansowany
            detailing, ochronę lakieru, czyszczenie wnętrza i niestandardowe
            zabiegi mające na celu odmłodzenie i ochronę pojazdu. Każda usługa
            jest wykonywana przez wysoko wykwalifikowanych specjalistów, którzy
            używają tylko najlepszych produktów i technik, aby zapewnić
            najlepszy wygląd samochodu.
          </Text>
          <Text
            sm-font="--headline3"
            margin="50px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Dlaczego Exoniva się wyróżnia
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            Dbałość o szczegóły: Każdemu zakamarkowi samochodu poświęcamy
            indywidualną uwagę.
            <br />
            <br />
            Niestandardowe rozwiązania: Dostosowujemy nasze usługi do unikalnych
            potrzeb Twojego pojazdu.
            <br />
            <br />
            Zrównoważone metody: Nasze ekologiczne podejście oznacza, że dbając
            o swój samochód, pomagasz także naszej planecie.
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://exoniva.com/img/5.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        
      </Section>
      <Section padding="80px 0" sm-padding="40px 0">
        <Override slot="SectionContent" align-items="center" />
        <Text
          as="p"
          font="--headline2"
          md-font="--headline2"
          margin="20px 0 0 0"
        >
          Dołącz do rodziny Exoniva
        </Text>
        <Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
          W Exoniva Studio nie tylko myjemy samochody - tworzymy historie. Twój
          pojazd jest częścią Twojej życiowej podróży, a my jesteśmy tutaj, aby
          zapewnić, że będzie lśnił po drodze. Niezależnie od tego, czy szukasz
          prostego mycia, czy kompletnej metamorfozy, wybierz nas i pozwól, aby
          Twój samochód odzwierciedlał najlepszą wersję Ciebie.
        </Text>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
